




















import {Component, Vue} from 'vue-property-decorator';
import FRSO_DTO from "@/dto/request/fincen/frso/FRSO_DTO";
import FinCENService from "@/services/request/FinCENService";
import {FRSOStatus} from "@/constants/request/RequestsStatuses";
import {namespace} from "vuex-class";

const AppModule = namespace("App");


@Component({
  computed: {
    FRSOStatus() {
      return FRSOStatus
    }
  }
})
export default class EmployeeFRSO extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private offer: FRSO_DTO | null = null;

  mounted() {
    this.loadOffer()
  }


  loadOffer() {
    this.startLoading();
    FinCENService.getOfferById(this.id).then(
        ok => {
          this.offer = ok.data;
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
        }
    );
  }

  changeStatus(status: FRSOStatus) {
    this.offer!.status = status;
    this.startLoading();
    FinCENService.updateOfferById(this.id, this.offer!).then(
        ok => {
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    );
  }

  get id(): number {
    return Number.parseInt(this.$route.params.id);
  }

}
